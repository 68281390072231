import {
  AntDesignOutlined,
  AppstoreOutlined,
  BookOutlined,
  CameraOutlined,
  CarOutlined,
  DashboardOutlined,
  DingtalkOutlined,
  EnvironmentOutlined,
  HighlightOutlined,
  HomeOutlined,
  LikeOutlined,
  LockOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ShakeOutlined,
  ShopOutlined,
  SkinOutlined,
  SolutionOutlined,
  StarOutlined,
  SwapOutlined,
  SwitcherOutlined,
  TagsOutlined,
  ThunderboltOutlined,
  UserOutlined,
  KeyOutlined,
  FileImageFilled,
  WechatOutlined,
  UpCircleOutlined,
  ToolOutlined ,
  PhoneOutlined,
  VideoCameraOutlined ,
  TranslationOutlined ,
  QuestionOutlined ,
  SettingOutlined ,
 } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { GiPodiumWinner } from "react-icons/gi";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";


const LIST_ORDERS = "ListOrders";
const LIST_REQUESTS = "ListRequests";
const GENERIC_OPTIONS = "GenericOptions";




const componentsNavTree = [
  {
    key: "components",
    path: `${APP_PREFIX_PATH}/apps/CRM`,
    title: "sidenav.support",
    icon: "",
    breadcrumb: true,
    submenu: [
      {
        key: "apps-project-chatroom",
        path: `${APP_PREFIX_PATH}/apps/CRM/chatroom`,
        title: "Chat Room",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      
    ],
  },
];


const navigationConfig =  
 
  [
    
    ...componentsNavTree,
    
  ]
 
  const this_for_return=()=>{
 
 
   return   [
  // [
    ...componentsNavTree,

   
   ]
 
    
  }


export default this_for_return();
